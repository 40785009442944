<template>
  <div class="flex min-h-screen">
    <div class="self-center max-w-md w-full mx-auto rounded bg-white shadow p-8">
      <div class="mb-10">
        <LiaLogo class="h-15" />
      </div>
      <form @submit.prevent="login">
        <div>
          <input v-model="email"
                 placeholder="Benutzername"
                 class="block w-full px-3 py-2 bg-white border border-neutral-300 rounded"
                 type="email">
        </div>
        <div class="mt-4">
          <input v-model="password"
                 placeholder="Passwort"
                 class="block w-full px-3 py-2 bg-white border border-neutral-300 rounded"
                 type="password">
        </div>
        <div class="mt-4">
          <button class="hover:text-yellow-300 text-xs uppercase px-5 py-3 bg-black rounded-md font-bold text-white" type="submit">
            Anmelden
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LiaLogo from "@/components/LiaLogo";

function AuthenticationError(message = '') {
  this.name = 'AuthenticationError'
  this.message = message
}
AuthenticationError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: AuthenticationError,
    writable: true,
    configurable: true
  }
});

export default {
  components: {
    LiaLogo
  },
  name: 'Home',
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async login() {
      const { email, password } = this;

      if (email && password) {
        try {
          const res = await fetch('/api/v1/users/sign_in', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ user: { email, password } })
          })

          if (res.ok && res.headers.has('Authorization')) {
            const token = res.headers.get('Authorization').split(' ')[1];
            this._persistToken(token);
          } else if (res.status === 401) {
            throw new AuthenticationError('oops' /* this.$t('errors.invalid_credentials') */);
          } else {
            throw new Error();
          }
        } catch (err) {
          // let errMsg = '';
          if (err instanceof AuthenticationError) {
            // errMsg = err.message;
            console.error(err);
          } else {
            // errMsg = 'unknown error'; // this.$t('errors.unknown')
            console.error(err);
          }
          // this.$toast.open({
          //   message: errMsg,
          //   type: 'is-danger'
          // })
        }
      }
    },
    _persistToken(token) {
      localStorage.setItem('jwt', token);

      window.location.href = '/';
    }
  }
}
</script>
